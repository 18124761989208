/* directory.css */

.directory {
    box-sizing: border-box;
    background-color: #161c29;
    border-radius: 20px;
    border: solid #282c34 2px;
    padding: 10px;
    cursor: pointer;
    height: 70px;
    transition: background-color 0.2s ease; /* Add smooth transition */
}

.text {
    margin: auto auto;
    text-align: center;
    line-height: 50px;

    font-size: 20px;
    color: #dcdfe4;
}

/* Hover effect */
.directory:hover {
    background-color: rgba(0,0,0,0.1); /* Change background color on hover */
    border: solid #4190ed 2px;
    box-sizing: border-box;
}

.directory.image:hover {
    background-color: green; /* Change background color on hover */
    border: solid lawngreen 2px;
    box-sizing: border-box;
    content: "New Text Content";
}

.directory.subject{
    height: 200px;
    width: 200px;
    padding: 0;
    overflow: hidden;
    transition: scale 0.2s ease;
}
.directory.subject img{
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border-radius: 20px;
}

.subject-text{
    color: whitesmoke;
    position: relative;
    bottom: 90px;
    text-align:  right;
    padding-right: 10px;
    font-size: 20px !important;
}


.directory.subject:hover {
    scale: 1.02;
}

