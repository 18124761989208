*{
  margin: 0;
  font-family: "FiraCode Nerd Font Mono", monospace;
}

.App {
  text-align: center;
}

#main{
  padding-top: 13vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #161c29;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#nav-bar{
  box-sizing: border-box;
  height: 12vh !important;
  max-height: 11vh !important;
  margin-bottom: 3vh;
  background-color:  #10141c;
  display: flex;
  position: fixed;
  width: 100%;
  box-shadow: #10141c 0px 10px 10px 10px;
  z-index: 110000000;
  gap: 10px;
}

#nav-bar a{
  margin-top: 3.8vh;
  color: #10141c;

  padding: 0 20px;
  font-size: 15px;
  font-weight: bold;
  line-height: 4vh;
  border-radius: 5px;
  height: 4vh;
  position: relative;
  display: inline-block;
  text-decoration: none;
  background-color: #c9cccc;
}

code{
  color: dodgerblue;
  font-style: italic;
}

#nav-bar a::after {
  content: '';
  position: absolute;
  left: 0;
  top: 4.5vh;
  width: 100%;
  height: 0.1em;
  background-color: #4190ed;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

#nav-bar a:hover::after,
#nav-bar a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.1em, 0);
}

/* Scale from center */
#nav-bar a::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

#nav-bar a:hover::after,
#nav-bar a:focus::after{
  transform: scale(0.7);
}

/* Cool infinite background scrolling animation.
 * Twitter: @kootoopas
 */
/* Exo thin font from Google. */

/* Background data (Original source: https://subtlepatterns.com/grid-me/) */
/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-webkit-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
/* Main styles */
body {
  font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
  text-align: center;
  /* img size is 50x50 */
  background: url("square.png") repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 0.92s infinite;
  /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse 0.92s infinite;
  /* Fx 5+ */
  -o-animation: bg-scrolling-reverse 0.92s infinite;
  /* Opera 12+ */
  animation: bg-scrolling-reverse 0.92s infinite;
  /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

#logo{
  display: flex;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
}

#logo img{
  margin-top: 1vh;
  height: 6vh;
}

#logo p{

    margin-top: 2vh;
    font-size: 3.7vh;
}
