.code-viewer{
    width: 65vw;
    height: 65vh;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0 0 10px 5px lightslategray;
    background-color: black;
    box-sizing: content-box;
    display: flex;
    overflow-y: scroll;
}
.code-viewer pre{
    width: 100%;
}
pre{
    overflow-x: scroll;
    display: block;
    font-size: 1rem !important;
    background-color: black !important;
}
/*pre{*/
/*    display: inline;*/
/*    font-size: 1.5rem !important;*/
/*    background-color: transparent !important;*/
/*}*/

/*code{*/
/*    color: white !important;*/
/*    font-size: 1.5rem !important;*/
/*    background-color: transparent !important;*/
/*    display: inline !important;*/
/*}*/

code{
    color: white !important;
    font-size: 1rem !important;
    background-color: transparent !important;
    display: inline !important;
}

.full-viewer{
    text-align: center;
    width: 65vw;
    margin: auto;
    margin-top: 2.5vh;
}

.frr{
    width: 60vw !important;
    height: 56vh !important;
    position: relative !important;
    left: 0.7vw !important;
}

.fe155{
    width: 95vw !important;
    height: 30vh !important;
    position: relative !important;
    right: 15.5vw !important;
}

@media (max-width: 768px){
    .fe155{

        width: 80vw !important;
        left: 2.5vw !important;
    }

    .frr{

        width: 77vw !important;
    }

    .code-viewer{
        width: 90vw;
    }
    .full-viewer{
        width: 90vw;
    }
}

li{
    color: lightskyblue;
}