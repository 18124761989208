#about{
    margin: 2rem;
}

#socials{
    margin: 2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
}

#socials button{
    border-radius: 5px;
    font-size: 1.5rem;
    min-width: 16rem;
    height: 2.5rem;
}

#Whatsapp{
    background-color: #25d366;
    color: white;
}

#Whatsapp:hover{
    background-color: #128c7e;
    box-shadow: none;
}

#Instagram {
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    background-size: 200% 200%; /* Adjust the size based on your needs */
    color: white;
    transition: background-position 0.5s;
}

#Instagram:hover {
    background-position: 80% 0;
    box-shadow: none; /* If you want to remove the box-shadow on hover */
}
#Baims {
    background: linear-gradient(45deg, dodgerblue 0%, #00c6ff 100%);
    background-size: 100%; /* Adjust the size based on your needs */
    color: white;
    transition: background-position 0.5s;
}

#Baims:hover {
    background-position: 80% 0;
    box-shadow: none; /* If you want to remove the box-shadow on hover */
}