@font-face {
    font-family: 'FiraCode Nerd Font Propo';
    src: url('Fonts/FiraCodeNFP-Light.woff2') format('woff2'),
        url('Fonts/FiraCodeNFP-Light.woff') format('woff'),
        url('Fonts/FiraCodeNFP-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Propo SemBd';
    src: url('Fonts/FiraCodeNFP-SemBd.woff2') format('woff2'),
        url('Fonts/FiraCodeNFP-SemBd.woff') format('woff'),
        url('Fonts/FiraCodeNFP-SemBd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Propo';
    src: url('Fonts/FiraCodeNFP-Bold.woff2') format('woff2'),
        url('Fonts/FiraCodeNFP-Bold.woff') format('woff'),
        url('Fonts/FiraCodeNFP-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Propo';
    src: url('Fonts/FiraCodeNFP-Reg.woff2') format('woff2'),
        url('Fonts/FiraCodeNFP-Reg.woff') format('woff'),
        url('Fonts/FiraCodeNFP-Reg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Propo Ret';
    src: url('Fonts/FiraCodeNFP-Ret.woff2') format('woff2'),
        url('Fonts/FiraCodeNFP-Ret.woff') format('woff'),
        url('Fonts/FiraCodeNFP-Ret.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Propo Med';
    src: url('Fonts/FiraCodeNFP-Med.woff2') format('woff2'),
        url('Fonts/FiraCodeNFP-Med.woff') format('woff'),
        url('Fonts/FiraCodeNFP-Med.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Mono SemBd';
    src: url('Fonts/FiraCodeNFM-SemBd.woff2') format('woff2'),
        url('Fonts/FiraCodeNFM-SemBd.woff') format('woff'),
        url('Fonts/FiraCodeNFM-SemBd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Mono';
    src: url('Fonts/FiraCodeNFM-Bold.woff2') format('woff2'),
        url('Fonts/FiraCodeNFM-Bold.woff') format('woff'),
        url('Fonts/FiraCodeNFM-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Mono';
    src: url('Fonts/FiraCodeNFM-Reg.woff2') format('woff2'),
        url('Fonts/FiraCodeNFM-Reg.woff') format('woff'),
        url('Fonts/FiraCodeNFM-Reg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Mono Med';
    src: url('Fonts/FiraCodeNFM-Med.woff2') format('woff2'),
        url('Fonts/FiraCodeNFM-Med.woff') format('woff'),
        url('Fonts/FiraCodeNFM-Med.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Mono';
    src: url('Fonts/FiraCodeNFM-Light.woff2') format('woff2'),
        url('Fonts/FiraCodeNFM-Light.woff') format('woff'),
        url('Fonts/FiraCodeNFM-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Mono Ret';
    src: url('Fonts/FiraCodeNFM-Ret.woff2') format('woff2'),
        url('Fonts/FiraCodeNFM-Ret.woff') format('woff'),
        url('Fonts/FiraCodeNFM-Ret.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Med';
    src: url('Fonts/FiraCodeNF-Med.woff2') format('woff2'),
        url('Fonts/FiraCodeNF-Med.woff') format('woff'),
        url('Fonts/FiraCodeNF-Med.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font SemBd';
    src: url('Fonts/FiraCodeNF-SemBd.woff2') format('woff2'),
        url('Fonts/FiraCodeNF-SemBd.woff') format('woff'),
        url('Fonts/FiraCodeNF-SemBd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font';
    src: url('Fonts/FiraCodeNF-Bold.woff2') format('woff2'),
        url('Fonts/FiraCodeNF-Bold.woff') format('woff'),
        url('Fonts/FiraCodeNF-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font';
    src: url('Fonts/FiraCodeNF-Reg.woff2') format('woff2'),
        url('Fonts/FiraCodeNF-Reg.woff') format('woff'),
        url('Fonts/FiraCodeNF-Reg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font';
    src: url('Fonts/FiraCodeNF-Light.woff2') format('woff2'),
        url('Fonts/FiraCodeNF-Light.woff') format('woff'),
        url('Fonts/FiraCodeNF-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FiraCode Nerd Font Ret';
    src: url('Fonts/FiraCodeNF-Ret.woff2') format('woff2'),
        url('Fonts/FiraCodeNF-Ret.woff') format('woff'),
        url('Fonts/FiraCodeNF-Ret.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

