.toggle-container {
    border-radius: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 1rem 0;
      background-color: #161c29;
      border: #4190ed solid 2px;
    height: 30px;
    gap: 10px;
    padding: 8px;
}

.toggle-container button {
    border-radius: 20px;

    height: 30px;
    margin:  10px 0;
    background-color: #161c29;
    color: white;
    font-size: 1.2rem;
    padding:0;
    line-height: 0.5rem;
    cursor: pointer;
}
.toggle-container button:hover {
    box-shadow: dodgerblue 0px 0px 5px 3px;
}
.toggle-container button:disabled {

    background-color: #4190ed;

}

@media (max-width: 720px) {
    .toggle-container {
        display: flex;
        flex-direction: row;
        gap:4px;
    }

    .toggle-container button {

        line-height: 1.5rem;
    }

}