#dirButtons {
    margin: 10vh auto 2vh auto;
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    white-space: nowrap;
    flex-wrap: wrap;
    cursor: pointer;
}

#dirButtons span {
    font-size: 20px;
    z-index: 10000;
    padding-left: 10px;
    padding-right: 5px;
}

#dirButtons span:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

@media (max-width: 720px) {
    #dirButtons {
        display: none;
        visibility: hidden;
    }
}