/* subject.css */

body {
    background-color: #10141c;
    color: #abb2bf;
}
.loading{
    margin: 0.5em;
    width: 13em;
    height: 1.5em;
    background-image: linear-gradient(to right, rgba(255,255,255,0.30) ,rgba(255,255,255,0) ,rgba(255,255,255,0),rgba(255,255,255,0.30));
    border-radius: 1em;
    background-size:400%;
    animation: gradient 1s ease infinite;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.directory-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 60vw;
    margin: auto;
    gap: 40px;
    padding: 50px;
}


.subject-container {
    display: grid;
    width: 60vw;
    margin: auto;
    gap: 40px;
    padding: 50px;
    justify-content: center;
}


@media (max-width: 768px) {
    .loading{
        width: 25em;
    }
    .directory-container {

        width: 80vw;
    }


}
.text {
    color: #dcdfe4;
}

/* Add any additional styling specific to your Subject component here */
