

h1 {
    color: #fff;
}

form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

label {
    margin: 30px;
    text-align: center;
    font-size: 1.2rem;
    display: block;
}

textarea {
    width: 100%;
    margin-bottom: 10px;
    color: whitesmoke;
}

textarea {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #75715e;
    background-color: #3E3D32;
    color: #f8f8f2;
    border-radius: 20px 0 0 20px;
    resize: none;
    z-index: 100;
    font-size: 1.1rem;

    height: 10vh;
    box-sizing: border-box;
    padding: 10px 15px;
}

button {
    background-color: #66d9ef;
    color: #272822;
    border: none;
    cursor: pointer;
    font-weight: normal;
    border-radius: 0 20px 20px 0;
    font-size: 2rem;
    margin-bottom: 10px;
    transition: background-color 0.3s;
    width: 10rem;
}

form button{
    height: 10vh;
}

.choice-container{
    display: flex;
}
.button-left {
    background-color: blue;
    color: whitesmoke;
    border: none;
    cursor: pointer;
    font-weight: normal;
    border-radius:0 0 0 20px;
    font-size: 1.5rem;
    margin-bottom: 10px;
    transition: background-color 0.3s;
    width: 33.333%
}
.button-middle {
    background-color: red;
    color: whitesmoke;
    border: none;
    cursor: pointer;
    font-weight: normal;
    border-radius:0;
    font-size: 1.5rem;
    margin-bottom: 10px;
    transition: background-color 0.3s;
    width: 33.333%;
    z-index: 2;
    min-height: 4rem;
}
.button-right {
    background-color: yellow;
    color: black;
    border: none;
    cursor: pointer;
    font-weight: normal;
    border-radius:0 0 20px 0;
    font-size: 1.5rem;
    margin-bottom: 10px;
    transition: background-color 0.3s;
    width: 33.333%;
    min-height: 4rem;
}

.loading-button{
    background-color: dimgray;
    color: whitesmoke;
    cursor: wait;
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: 10px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 4rem;
}

button:hover {
    box-shadow: 0 0 10px 5px lightskyblue;
}
.button-left:hover {
    box-shadow: 0 0 10px 5px palevioletred;
}
.button-middle:hover {
    box-shadow: 0 0 10px 5px royalblue;
}
.button-right:hover {
    box-shadow: 0 0 10px 5px lightgoldenrodyellow;
}

#response {
    margin-top: 20px;
}

p {
    color: whitesmoke;
    line-height: 1.6rem;
    font-size: 1.2rem;
    text-align: left;
}

strong{
    color: greenyellow;
}


.error{
    color: red;
}
li{

    line-height: 1.4rem;
    font-size: 1rem;
    text-align: left;
}

h2{
    color: yellow;
    text-align: left;
}

h3{
    color: dodgerblue;
    text-align: left;
}

em{
    background-color: midnightblue;
    color: skyblue;
    display: block;
    padding: 10px;
    text-align: left;
    font-size: 1rem;
    line-height: 1.3rem;
}
[contentEditable="true"] {
    border: none;
    outline: none; /* This removes the outline when the element is focused */
}
#loading {
    display: block;
    color: #0c0d13;
    z-index: 10000;
    position: relative;
}


.lds-ring div {

    box-sizing: border-box;
    display: block;
    width: 50px;
    height: 50px;
    margin: 8px auto;
    border: 6px solid #66d9ef;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #66d9ef transparent transparent transparent;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ai{
    width: 65vw;
    margin: auto;
    padding: 0;
    box-sizing: content-box;
}


div{
    text-align: left;
}

.flat{
    border-radius: 20px 20px 0 0 ;
}

.question-content{
    width: 90vw;
    text-align: center;
    position: relative;
    right: 22%;
}
@media (max-width: 768px){
    .ai{
        width: 90vw;
    }
    button {
        font-size: 1.3rem !important;
    }

    .question-content{
        right: 0;
    }
}